export default {
  supergens: {
    searchText: '',
    loadingSupergens: false,
    loadingSoundFilters: false,
    supergens: [],
    filteredSupergens: [],
    sounds: [],
    filteredSounds: []
  }
};
